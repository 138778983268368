import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { connect } from 'react-redux';
import { loginAction, getUserInfoAction, signUpAction } from '../store/actions/user';

import { Form, Button, Input, message } from "antd";

const SignUp = (props) => {
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const onFinish = (values) => {
        // props.loginAction(values).then(resp => {
        //     const { code, msg, data } = resp;
        //     if (code === 200) {
        //         // message.success(msg);
        //         form.resetFields();
        //         props.getUserInfoAction(data.token);
        //         navigate("/");
        //     } else {
        //         message.error(msg);
        //     }
        // });
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };

    return (
        <div className='login-wrapper'>
            <Header className="header01" />
            <section className="tf-login tf-section">
                <div className="themes-flat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                                Sign Up To IMetaID
                            </h2>

                            {false && <div className="flat-form box-login-social">
                                <div className="box-title-login">
                                    <h5>Login with social</h5>
                                </div>
                                <ul>
                                    <li>
                                        <Link to="#" className="sc-button style-2 fl-button pri-3">
                                            <i className="icon-fl-google-2"></i>
                                            <span>Google</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="sc-button style-2 fl-button pri-3">
                                            <i className="icon-fl-facebook"></i>
                                            <span>Facebook</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>}

                            <div className="flat-form box-login-email">
                                {false && <div className="box-title-login">
                                    <h5>Or login with email</h5>
                                </div>}
                                <div className="form-inner">
                                    <Form form={form} onFinish={onFinish} id="contactform" onFinishFailed={onFinishFailed} >
                                        <Form.Item name="email" rules={[
                                            { required: true, message: 'Email is required', },
                                        ]}>
                                            <Input className='input-item' placeholder="Your email address" />
                                        </Form.Item>
                                        <Form.Item name="passowrd" rules={[
                                            { required: true, message: 'Passoword is required', },
                                        ]}>
                                            <Input.Password className='input-item' placeholder="Your password" />
                                        </Form.Item>
                                        <Form.Item name="confrimPassowrd" rules={[
                                            { required: true, message: 'Confirm passoword is required', },
                                        ]}>
                                            <Input.Password className='input-item' placeholder="Your confirm password" />
                                        </Form.Item>
                                        <div className="row-form style-1">
                                            <label>  I confirm that the country selection is correct, agree to the Terms of Service and give consent to collect, use and store my data according to your  Privacy Policy
                                                <input type="checkbox" />
                                                <span className="btn-checkbox"></span>
                                            </label>
                                        </div>
                                        <Form.Item >
                                            <Button htmlType="submit" className="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default connect(state => state.user, { loginAction, getUserInfoAction, signUpAction })(SignUp);