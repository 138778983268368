
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import Countdown from "react-countdown";
import liveAuctionData from 'assets/fake-data/data-live-auction';
import LiveAuction from 'components/layouts/LiveAuction';
import Accordion from 'react-bootstrap/Accordion';
import ProductDetailsThumbnail from 'components/product-details/Thumbnail';
import products from 'assets/fake-data/data-products.json'
import { useProduct } from "hooks";
import cogoToast from "cogo-toast";
import { CartContext } from "global/CartContext";
import { CompareContext } from "global/CompareContext";
import { WishlistContext } from "global/WishlistContext";
import { CURRENCY } from "utils/constant";
import ProductRatings from 'components/product/elements/ProductRatings';
import ProductColor from "components/product/elements/ProductColor";
import ProductMaterial from "components/product/elements/ProductMaterial";
import ProductSize from "components/product/elements/ProductSize";
import {
    getCartProduct,
    getCartProductQuantity,
    getDiscountPrice,
    getProductUniqueSizes,
    getWishCompareProduct
} from "utils/product";

const ProductDetails = (props) => {
    const [product, setProduct] = useState(null)
    let { id } = useParams();

    // console.log('id', id)

    useEffect(() => {
        setProduct(products.find(item => item.id == id))
    })

    return (product && <ProductDetailsContent product={product} />)
}

const ProductDetailsContent = ({ product, showMaterial = false }) => {
    const {
        name,
        badge,
        description,
        discount,
        discountDuration,
        excerpt,
        previewImages,
        ratings,
        sku,
        variations
    } = product;

    const {
        productSize,
        productColor,
        productPrice,
        productStock,
        modalCartAdded,
        productMaterial,
        productQuantity,
        setProductQuantity,
        compareHandler,
        wishlistHandler,
        productSizeHandler,
        productColorHandler,
        modalCartAddedHandler,
        productMaterialHandler,
        productQuantityIncrement,
        productQuantityDecrement
    } = useProduct(product);


    const sizes = getProductUniqueSizes(product);

    const { wishlist } = useContext(WishlistContext);
    const { compareList } = useContext(CompareContext);
    const { shoppingCart, addToCart } = useContext(CartContext);

    const isInWishlist = getWishCompareProduct(wishlist, product);
    const isInCompareList = getWishCompareProduct(compareList, product);
    const discountedPrice = getDiscountPrice(productPrice, discount);
    const isInCart = getCartProduct(shoppingCart, product, productColor, productSize, productMaterial);
    const cartProductQuantity = getCartProductQuantity(shoppingCart, product, productColor, productSize);

    const addToCartHandler = () => {
        addToCart({
            ...product,
            price: productPrice,
            color: productColor,
            size: productSize,
            material: productMaterial,
            quantity: productQuantity
        });
        setProductQuantity(1);
        !isInCart ? modalCartAddedHandler() : cogoToast.success(
            `Your Item "${product.name}" already added.`, {
            position: "bottom-right",
            heading: "Added to Cart!",
            hideAfter: 2
        }
        );
    }

    return (
        <div className='item-details product-detail-wrapper'>
            <Header className="header01" />
            <div className="tf-section tf-item-details">
                <div className="themes-flat-container">
                    <div className="row">
                        <div className="col-md-6">
                            <ProductDetailsThumbnail thumbImages={product.previewImages} productName={name} />
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">{product.name} </h2>
                                    {/* <div className="meta-item">
                                            <div className="left">
                                                <span className="viewed eye">225</span>
                                                <span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span>
                                            </div>
                                            <div className="right">
                                                <Link to="#" className="share"></Link>
                                                <Link to="#" className="option"></Link>
                                            </div>
                                        </div> */}
                                    <div className="tt-add-info">
                                        <ul>
                                            <li><span>SKU:</span> {sku}</li>
                                            <li>
                                                <span>Availability:</span>
                                                {productStock === cartProductQuantity ? 'out of stock' : (productStock - cartProductQuantity) <= 3 ? ` less stock` : 'instock'}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tt-price">
                                        {!discount ? (
                                            CURRENCY + productPrice.toFixed(2)
                                        ) : (
                                            <Fragment>
                                                <span className="sale-price mr-3">
                                                    {CURRENCY + discountedPrice.toFixed(2)}
                                                </span>
                                                <span className="old-price">
                                                    {CURRENCY + productPrice.toFixed(2)}
                                                </span>
                                            </Fragment>
                                        )}
                                    </div>
                                    <ProductRatings ratings={ratings} />


                                    {variations && (
                                        <div className="tt-swatches-container">
                                            <div className="tt-wrapper">
                                                <div className="tt-title-options">COLOR:</div>
                                                <ProductColor
                                                    product={product}
                                                    productColor={productColor}
                                                    className="options-large mt-1"
                                                    productColorHandler={productColorHandler}
                                                />
                                            </div>

                                            <div className="tt-wrapper">
                                                <div className="tt-title-options">SIZE:</div>
                                                <ProductSize
                                                    className="options-large mt-1"
                                                    product={product}
                                                    productColor={productColor}
                                                    productSize={productSize}
                                                    productSizeHandler={productSizeHandler}
                                                />
                                            </div>

                                            {showMaterial && <div className="tt-wrapper mt-3">
                                                <div className="tt-title-options">MATERIAL:</div>
                                                <ProductMaterial
                                                    className="options-large mt-1"
                                                    product={product}
                                                    productColor={productColor}
                                                    productMaterial={productMaterial}
                                                    productMaterialHandler={productMaterialHandler}
                                                />
                                            </div>}
                                        </div>
                                    )}
                                    <p className='desc'>{excerpt}</p>

                                    <div className="meta-item-details style2">
                                        <div className="item count-down">
                                            <span className="heading style-2">Countdown</span>
                                            <Countdown date={Date.now() + 500000000}>
                                                <span>You are good to go!</span>
                                            </Countdown>
                                        </div>
                                    </div>


                                    <div className="tt-wrapper">
                                        <div className="tt-row-custom-01">
                                            <div className="col-item">
                                                <div className="tt-input-counter style-01">
                                                    <span
                                                        className="minus-btn"
                                                        onClick={productQuantityDecrement}
                                                    />
                                                    <input
                                                        type="text"
                                                        value={productQuantity}
                                                        size={productStock}
                                                        readOnly
                                                    />
                                                    <span
                                                        className="plus-btn"
                                                        onClick={productQuantityIncrement}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-item">
                                                <button onClick={addToCartHandler} className={`sc-button loadmore style bag fl-button pri-3 `} disabled={productStock === cartProductQuantity ? true : false} >
                                                    <span>{productStock === cartProductQuantity ? "SOLD OUT" : 'ADD TO CART'} </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="tt-wrapper">

                                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>DESCRIPTION</Accordion.Header>
                                                <Accordion.Body>
                                                    {excerpt}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>MATERIAL</Accordion.Header>
                                                <Accordion.Body>
                                                    {excerpt}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>ADDITIONAL INFORMATION</Accordion.Header>
                                                <Accordion.Body>
                                                    {excerpt}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LiveAuction data={liveAuctionData} />
            <Footer />
        </div>
    );
}


export default ProductDetails;
