import React, { useState } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider-lottery';
import Slider from '../components/slider/Slider';
import SliderLottery from '../components/slider/SliderLottery';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import TopSeller from '../components/layouts/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller'
import TodayPicks from '../components/layouts/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';
import Winners from '../components/layouts/lottery/Winners';
import { Button, Divider, notification, Space, Modal, message } from 'antd';

import client from '../services/apiService';

const Lottery = () => {

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (type, placement, message) => {
        api[type]({
            message: `${message}`,
            // description: `hello`,
            placement,
        });
    };

    const loadToken = async () => {
        console.log(`first`)

        // openNotification(`info`, `topRight`, `Please wait transaction complete`)

        Modal.success({
            title: 'Notice',
            content: `Transaction is submited, view detail on https://goerli.etherscan.io/tx/`,
            width: 1000,
        });

        const resp = await client.get(`token/queryToken/0x5fb7c74ee786f38c945c56150b0735f3e696cc91`);
        if (resp?.data?.data) {
            const { amount } = resp?.data?.data;
            console.log('amount', amount)
        }
    }

    return (
        <div className='home-1'>
            {contextHolder}
            <Header />
            <SliderLottery data={heroSliderData} />
            <Winners />
            {/* <LiveAuction data={liveAuctionData} /> */}
            {/* <TopSeller data={topSellerData} /> */}
            {/* <TodayPicks data={todayPickData} /> */}
            <PopularCollection data={popularCollectionData} />
            {/* <button class="sc-button loadmore fl-button pri-3" onClick={loadToken}><span>Get</span></button> */}
            <Create />
            <Footer />
        </div>
    );
}

export default Lottery;
