import React, { useState, useEffect, useContext, useCallback, memo } from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import TopSeller from '../components/layouts/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller'
import TodayPicks from '../components/layouts/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';
import { Button, Divider, notification, Space, Modal, message } from 'antd';

import client from '../services/apiService';
import { signMessage, signMakerOrderMessage, eip712Hash, eip712Hash2 } from '../services/orderService';


import { connect } from "react-redux";
import { getUserInfoAction } from "../store/actions";


const Home01 = (props) => {

    console.log('props', props)

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (type, placement, message) => {
        api[type]({
            message: `${message}`,
            // description: `hello`,
            placement,
        });
    };

    const loadToken = async () => {
        console.log(`first`)
        // openNotification(`info`, `topRight`, `Please wait transaction complete`)
        const resp = await client.get(`/api/token/0x5fb7c74ee786f38c945c56150b0735f3e696cc91`);
        if (resp?.data?.data) {
            const { amount } = resp?.data?.data;
            console.log('amount', amount)

            Modal.success({
                title: 'Notice',
                content: `${amount}`,
                width: 1000,
            });
        }
    }

    const handleSignMessage = async () => {
        const order = { to: '0xc5B62e6283A43b3595b25A175759Ae4f30DdFd02', amount: 123, message: "hello123", nonce: 321 }
        signMakerOrderMessage(order);
    }

    return (
        <div className='home-1'>
            {contextHolder}
            <Header />
            <Slider data={heroSliderData} />

            {/* <button className="sc-button loadmore fl-button pri-3" onClick={loadToken}><span>Get</span></button> */}
            {/* <button className="sc-button loadmore fl-button pri-3" onClick={() => signMessage(`Sign to login to your IMETAID account. This is free and will not require a transaction. \n\nOne-Time nonce: 9a8758565eef5356cce516`)}><span>personal sign mesasge</span></button>
            <button className="sc-button loadmore fl-button pri-3" onClick={handleSignMessage}><span>Order sign</span></button>

            */}
            {/* <button className="sc-button loadmore fl-button pri-3" onClick={eip712Hash}><span>eip712Hash</span></button>
            <button className="sc-button loadmore fl-button pri-3" onClick={eip712Hash2}><span>eip712Hash2</span></button> */}

            <LiveAuction data={liveAuctionData} />
            <TopSeller data={topSellerData} />
            <TodayPicks data={todayPickData} />
            <PopularCollection data={popularCollectionData} />

            <Create />
            <Footer />
        </div>
    );
}

export default connect((state) => ({ ...state.user, ...state.settings, }), { getUserInfoAction })(Home01);