import axios from "axios";


const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  rejectUnauthorized: false
  // timeout: 5000, // request timeout
});

// Add a request interceptor
client.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
client.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default client;