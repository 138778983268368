import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import { connect, Connect } from 'react-redux';
import menus from "pages/menu";
import DarkMode from './DarkMode';
import logoheader from 'assets/images/logo/newlogo3.png'
import logoheader2x from 'assets/images/logo/newlogo3.png'
import logodark from 'assets/images/logo/newlogo3.png'
import logodark2x from 'assets/images/logo/newlogo3.png'
import imgsun from 'assets/images/icon/sun.png'
import avt from 'assets/images/avatar/avt-2.jpg'
import { shortenAddress } from 'utils/shortenAddress';
import { resetUserAction, walletChangedAction } from 'store/actions/user';
import MiniCart from './elements/MiniCart';

const { ethereum } = window;

const Header = (props) => {
    const className = props.className || "";

    // console.log('props', props)
    const { pathname } = useLocation();

    const headerRef = useRef(null)
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('.js-header');
        const scrollTop = window.scrollY;
        scrollTop >= 300 ? header.classList.add('is-fixed') : header.classList.remove('is-fixed');
        scrollTop >= 400 ? header.classList.add('is-small') : header.classList.remove('is-small');
    };

    const menuLeft = useRef(null)
    const btnToggle = useRef(null)
    const btnSearch = useRef(null)

    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const searchBtn = () => {
        btnSearch.current.classList.toggle('active');
    }

    const [activeIndex, setActiveIndex] = useState(0);
    const handleOnClick = index => {
        setActiveIndex(index);
    };

    const handleLogout = () => {
        props.resetUserAction()
    }


    const autoConnectWallet = async () => {
        if (localStorage.getItem("last-connected-wallet") === "true") {
            try {
                if (!ethereum) return;
                const accounts = await ethereum.request({ method: "eth_accounts" });
                if (accounts.length) {
                    const account = accounts[0].toLowerCase();
                    props.walletChangedAction(account);
                } else {
                    console.log("No accounts found");
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const accountsChanged = () => {
        if (ethereum) {
            ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length > 0) {
                    const account = accounts[0].toLowerCase();
                    props.walletChangedAction(account);
                } else {
                    props.walletChangedAction("");
                }
            });

            ethereum.on('chainChanged', (chainId) => {
                // window.location.reload();
            });

            ethereum.on('disconnect', () => {
                // setCurrentAccount('');
            });
        }
    }

    const [isHidden, setIsHidden] = useState({ miniCart: true, });
    const openHandler = (e) => {
        setIsHidden(prev => {
            return {
                miniCart: true,
                [e.target.dataset.id]: !prev[e.target.dataset.id]
            }
        });
    };
    const closeHandler = (e) => {
        e.preventDefault();
        setIsHidden(prev => {
            return {
                ...prev,
                [e.target.dataset.id]: !prev[e.target.dataset.id]
            }
        });
    }
    useEffect(() => {
        if (ethereum) {
            autoConnectWallet();
            accountsChanged(); // refresh page if account changed
        } else {
            console.log("Please install MetaMask.");
        }
    }, [])

    return (
        <header id="header_main" className={`header-wrapper header_1 js-header ${className}`} ref={headerRef}>
            <div className="themes-flat-container">
                <div id="site-header-inner">
                    <div className="wrap-box flex">
                        <div id="site-logo" className="clearfix">
                            <div id="site-logo-inner">
                                <Link to="/" rel="home" className="main-logo">
                                    <img className='logo-dark' id="logo_header" src={logodark} srcSet={`${logodark2x}`} alt="nft-gaming" />
                                    <img className='logo-light' id="logo_header" src={logoheader} srcSet={`${logoheader2x}`} alt="nft-gaming" />
                                </Link>
                            </div>
                        </div>
                        <nav id="main-nav" className="main-nav" ref={menuLeft} >
                            <ul id="menu-primary-menu" className="menu">
                                {
                                    menus.map((data, index) => (
                                        <li key={index} onClick={() => handleOnClick(index)}
                                            className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === index ? 'active' : ''}`}   >
                                            {data.type === 'internal' ? <Link to={data.links}>{data.name}</Link> : <a href={`${data.links}`} target="_blank">{data.name}</a>}
                                            {
                                                data.namesub &&
                                                <ul className="sub-menu" >
                                                    {
                                                        data.namesub.map((submenu) => (
                                                            <li key={submenu.id} className={
                                                                pathname === submenu.links
                                                                    ? "menu-item current-item"
                                                                    : "menu-item"
                                                            }><Link to={submenu.links}>{submenu.sub}</Link></li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
            <div className="flat-search-btn flex">
                <div className="header-search flat-show-search" id="s1">
                    <Link to="#" className="show-search header-search-trigger" onClick={searchBtn}>
                        <i className="far fa-search"></i>
                    </Link>
                    <div className="top-search" ref={btnSearch}>
                        <form action="#" method="get" role="search" className="search-form">
                            <input type="search" id="s" className="search-field" placeholder="Search..." name="s" title="Search for" required="" />
                            <button className="search search-submit" type="submit" title="Search">
                                <i className="icon-fl-search-filled"></i>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="sc-btn-top mg-r-12" id="site-header">
                    {!props.currentAccount && <Link to="/wallet-connect" className="sc-button header-slider style style-1 wallet fl-button pri-1 connect">
                        <span>Wallet connect
                        </span>
                    </Link>}
                    {!props.userName && <Link to="/login" className="sc-button header-slider style style-1 wallet fl-button pri-1 login">
                        <span>Sign In / Sign Up
                        </span>
                    </Link>}
                </div>

                <div className="tt-col-obj tt-obj-options">
                    <MiniCart
                        isHidden={isHidden.miniCart}
                        openHandler={openHandler}
                        closeHandler={closeHandler}
                        className="tt-desktop-parent-cart"
                    />
                </div>
                {
                    props.userName && <div className="admin_active" id="header_admin">
                        <div className="header_avatar">
                            {props.currentAccount && <div className="price">
                                <span>2.45 <strong>ETH</strong> </span>
                            </div>}
                            <img
                                className="avatar"
                                src={avt}
                                alt="avatar"
                            />
                            <div className="avatar_popup visible mt-20">
                                {props.currentAccount && <div>
                                    <div className="d-flex align-items-center copy-text justify-content-between">
                                        <span> {shortenAddress(props.currentAccount)} </span>
                                        <Link to="/" className="ml-2">
                                            <i className="fal fa-copy"></i>
                                        </Link>
                                    </div>
                                    <div className="d-flex align-items-center mt-10">
                                        <img
                                            className="coin"
                                            src={imgsun}
                                            alt="/"
                                        />
                                        <div className="info ml-10">
                                            <p className="text-sm font-book text-gray-400">Balance</p>
                                            <p className="w-full text-sm font-bold text-green-500">2.45 ETH</p>
                                        </div>
                                    </div>
                                    <div className="hr mt-20"></div>
                                </div>}
                                <div className="links ">
                                    <Link to="/profiles">
                                        <i className="fal fa-book-reader" /><span>Profiles</span>
                                    </Link>
                                    <Link to="/account">
                                        <i className="fal fa-book-reader" /><span>Account</span>
                                    </Link>
                                    <Link to="/orders">
                                        <i className="fal fa-bags-shopping" /><span>Orders</span>
                                    </Link>
                                    <Link to="/nfts">
                                        <i className="fal fa-gift" /><span>NFTs</span>
                                    </Link>
                                    <Link to="/membership">
                                        <i className="fal fa-comments" /><span>Membership</span>
                                    </Link>
                                    <button onClick={handleLogout} id="logout">
                                        <i className="fal fa-sign-out"></i> <span>Logout</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="mobile-button" ref={btnToggle} onClick={menuToggle}><span></span></div>
            </div >
            <DarkMode />
        </header >
    );
}

export default connect(state => state.user, { resetUserAction, walletChangedAction })(Header);
