import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

import img1 from '../../assets/images/box-item/image-box-34.jpg'

const PurchaseModal = (props) => {

    const { nft } = props

    const handleCancel = () => {
        console.log(`handleCancel`)
    }
    const handleBuy = () => {
        console.log(`handleBuy`)
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 purchase-modal">
                <h3>Buy a NFT</h3>
                <div className=''>
                    <div className=''>
                        <img className='img' src={nft.img} alt={nft.title} />
                    </div>
                    <div className='info'>
                        <h6>{nft.title}</h6>
                    </div>
                </div>

                <div className="hr"></div>
                <div className="d-flex justify-content-between">
                    <p>Price:</p>
                    <p className="text-right price color-popup"> {nft.price} ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p> Service free:</p>
                    <p className="text-right price color-popup"> 0,89 ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                    <p> Total amount:</p>
                    <p className="text-right price color-popup"> 4 ETH </p>
                </div>

                {/* <p className='msg'>Purchase succeeded</p> */}
                <div className="hr"></div>
                <div className='bottom-btns'>
                    <button onClick={props.onHide} className="sc-button loadmore fl-button pri-3"><span>Cancel</span></button>
                    <button onClick={handleBuy} className="sc-button loadmore fl-button pri-3"><span>Buy</span></button>
                </div>
            </div>
        </Modal >

    );
};

export default connect(state => state.user)(PurchaseModal);
