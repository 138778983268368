import React, { useState, Fragment, useContext } from 'react';
import PropTypes from 'prop-types'
import cogoToast from "cogo-toast";
import { useProduct } from 'hooks';
import { CartContext } from 'global/CartContext';
import { getCartProductQuantity, getCartProduct } from "utils/product";
import { Link } from 'react-router-dom'
import CardModal from './CardModal';
import { CURRENCY } from 'utils/constant';
import ProductRatings from 'components/product/elements/ProductRatings';

const Product = ({ product }) => {

    const { id, badge, discount, discountDuration, name, ratings, thumbs, variations, vendor } = product;

    const {
        slug,
        productSize,
        productColor,
        productPrice,
        discountedPrice,
        productStock,
        isOutOfStock,
        modalCartAdded,
        modalQuickView,
        productMaterial,
        productQuantity,
        productColorImage,
        productSizeHandler,
        productColorHandler,
        modalQuickViewHandler,
        modalCartAddedHandler,
        productMaterialHandler
    } = useProduct(product);

    const { shoppingCart, addToCart } = useContext(CartContext);
    const isInCart = getCartProduct(shoppingCart, product, productColor, productSize, productMaterial);
    const cartProductQuantity = getCartProductQuantity(shoppingCart, product, productColor, productSize);

    const handleAddToCart = () => {
        console.log(`handleAddToCart`)
    }

    return <div key={product.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        <div className={`sc-card-product`}>
            <div className="card-media">
                <Link to={`/product-details/${id}`}><img src={thumbs[0]} alt={name} /></Link>
            </div>

            <ul className="tt-add-info">
                <li>
                    <Link href="/"> {vendor} </Link>
                </li>
            </ul>
            <ProductRatings ratings={ratings} />
            <div className="card-title">
                <h5 className="style2"><Link to="/product-details">"{name}"</Link></h5>
            </div>

            <div className="meta-info">
                <div className="price">

                    {!discount ? (
                        <span className="new-price">
                            {CURRENCY + productPrice.toFixed(2)}
                        </span>
                    ) : (
                        <Fragment>
                            <span className="new-price">
                                {CURRENCY + discountedPrice.toFixed(2)}
                            </span>
                            <span className="old-price">
                                {CURRENCY + productPrice.toFixed(2)}
                            </span>
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="card-bottom">
                <button disabled={productStock === cartProductQuantity ? true : false}
                    className={`sc-button style bag fl-button pri-3 no-bg add-to-cart`}
                    onClick={() => {
                        addToCart({
                            ...product,
                            price: discountedPrice ? discountedPrice : productPrice,
                            color: productColor,
                            size: productSize,
                            material: productMaterial,
                            quantity: productQuantity
                        });
                        !isInCart ? modalCartAddedHandler() : cogoToast.success(
                            `Your Item "${product.name}" already added.`, {
                            position: "bottom-right",
                            heading: "Added to Cart!",
                            hideAfter: 4
                        }
                        );
                    }}
                >
                    <span>{productStock === cartProductQuantity ? "SOLD OUT" : "ADD TO CART"}</span>
                </button>
            </div>
        </div>
    </div>
}


const TodayDeals = ({ data, className }) => {
    const [visible, setVisible] = useState(8);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }
    const [modalShow, setModalShow] = useState(false);

    return (
        <Fragment>
            <section className={`tf-section today-pick ${className}`}>
                <div className="themes-flat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-live-auctions mg-bt-21">
                                <h2 className="tf-title pad-l-7">
                                    Today's Deals
                                </h2>
                                <Link to="/explore-03" className="exp style2">EXPLORE MORE</Link>
                            </div>
                        </div>
                        {
                            data.slice(0, visible).map((product, index) => <Product key={product.id} product={product} />)
                        }
                        {
                            visible < data.length &&
                            <div className="col-md-12 wrap-inner load-more text-center">
                                <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                            </div>
                        }
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment >
    );
}



TodayDeals.propTypes = {
    data: PropTypes.array.isRequired,
}


export default TodayDeals;
