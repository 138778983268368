import * as types from "../action-types";
import lscache from "lscache";
import { PREFIX } from "utils/constant";
import { setToken, removeToken } from "../../utils/auth";
import { login } from '../../services/userService'
const { ethereum } = window;

export const loginAction = ({ userName, password }) => async (dispatch) => {
  if (userName === 'admin') {
    const result = {
      code: 200,
      msg: "success",
      data: { token: "token01" }
    };

    const token = result.data.token;
    dispatch(setUserTokenAction(token));
    setToken(token);
    return result;
  } else {
    return {
      code: 500,
      msg: "username or password not correct"
    }
  }
};

export const getUserInfoAction = (token) => async (dispatch) => {
  const userInfo = { userName: "user1", role: "admin", avatar: "/media/avt-2.jpg", email: "xxx@xxx.com", token }
  dispatch(setUserInfoAction(userInfo));
  return userInfo;
};

export const connectWalletAction = () => async (dispatch) => {
  try {
    if (!ethereum) return;
    const accounts = await ethereum.request({ method: "eth_requestAccounts", });
    const account = accounts[0].toLowerCase();
    // console.log('account', account)
    dispatch({ type: types.USER_CONNECT_WALLET, currentAccount: account });
  } catch (error) {
    console.log(error);
  }
}

export const signUpAction = () => async (dispatch) => {
}

export const walletChangedAction = (account) => async (dispatch) => {
  try {
    dispatch({ type: types.USER_CONNECT_WALLET, currentAccount: account });
  } catch (error) {
    console.log(error);
  }
}

export const setUserTokenAction = (token) => {
  return {
    type: types.USER_SET_USER_TOKEN,
    token,
  };
};

export const setUserInfoAction = (userInfo) => {
  lscache.set(PREFIX + "-user", userInfo);
  return {
    type: types.USER_SET_USER_INFO,
    ...userInfo,
  };
};

export const resetUserAction = () => {
  lscache.remove(PREFIX + "-user");
  return {
    type: types.USER_RESET_USER,
  };
};
