import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import TodayPicks from '../components/layouts/myItem/TodayPicks'
import todayPickData from '../assets/fake-data/data-today-pick';

const MyItem = () => {
    return (
        <div>
            <Header className="header01" />
            <div className="themes-flat-container">
                <div className="row">
                    <div className="col-12">
                        {/* <h2 className="tf-title-heading ct style-1">
                            Orders
                        </h2> */}
                        <TodayPicks data={todayPickData} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MyItem;
