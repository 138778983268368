import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '../components/header/Header';
import ItemContent from '../components/layouts/marketplace/ItemContent';
import SideBar from '../components/layouts/marketplace/SideBar';
import { Container, Col, Row } from "react-bootstrap";

const Marketplace = (props) => {
    return (
        <div className='home-8 marketplace'>
            <Header className='header01' />
            {/* <section className="flat-title-page inner header01"> </section> */}
            <section>

                <div className="row">
                    <div className="col-sm-4 col-lg-2 pl-0 pr-0">
                        <SideBar />
                    </div>

                    <div className="col-sm-8 col-lg-10 pl-0 pr-0">
                        <ItemContent />
                    </div>
                </div>

                {/* <Row>
                        <Col lg={6} xl={12}>
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={6} xl={2}>
                            <SideBar />
                        </Col>
                        <Col lg={6} xl={10}>
                            <ItemContent />
                        </Col>
                    </Row> */}

            </section>
        </div>
    );
}

export default connect(state => state.user)(Marketplace);