import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import { Form, Button, Input, message } from "antd";

const ForgotPassword = () => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
    };


    return (
        <div className='login-wrapper'>
            <Header className="header01" />
            <section className="tf-login tf-section">
                <div className="themes-flat-container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="tf-title-heading ct style-1">
                                Recover Account
                            </h2>

                            <div className="flat-form box-login-email">

                                <div className='forgotpasword-box-title'>Enter the email address you registered with and we will send you a link to reset your password!</div>
                                <div className="form-inner">
                                    <Form form={form} onFinish={onFinish} id="contactform" onFinishFailed={onFinishFailed} >
                                        <Form.Item name="emailAddress" rules={[
                                            { required: true, message: 'Email is required', },
                                        ]}>
                                            <Input className='input-item' placeholder="Your email" />
                                        </Form.Item>
                                        {/* <Form.Item name="phone" rules={[
                                            { required: true, message: 'Phone is required', },
                                        ]}>
                                            <Input className='input-item' placeholder="Your Phone" />
                                        </Form.Item> */}
                                        {/* <div className="row-form style-1">
                                            <label>Remember me
                                                <input type="checkbox" />
                                                <span className="btn-checkbox"></span>
                                            </label>
                                            <Link to="/forgotPassword" className="forgot-pass">Forgot Password ?</Link>
                                            <Link to="/sign-up" className="forgot-pass">Sign Up</Link>
                                        </div> */}
                                        <Form.Item >
                                            <Button htmlType="submit" className="submit">
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ForgotPassword;
