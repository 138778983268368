import React, { } from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import heroSliderData from 'assets/fake-data/data-slider';
import SliderDeals from 'components/slider/SliderDeals';
import TodayDeals from 'components/layouts/TodayDeals';
import todayPickData from 'assets/fake-data/data-today-pick';
import products from "assets/fake-data/data-products.json"
import { connect } from "react-redux";
import { getUserInfoAction } from "store/actions";

const Deals = (props) => {
    return (
        <div className='deals-page-wrapper'>
            <Header />
            <SliderDeals data={heroSliderData} />
            <TodayDeals className='today-deals-wrapper' data={products} />
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.settings,
    };
};

export default connect(mapStateToProps, { getUserInfoAction })(Deals);