import PropTypes from "prop-types";
import { isFloat } from "utils/isFloat";

const ProductRatings = ({ ratings, className }) => {

    return (
        <div className={`tt-rating ${className ? className : ''}`}>
            {/* Full Star */}
            {Array.from({ length: ratings }, (_, i) => (
                <i key={i} className="icon-h-14 icon-star" />
            ))}

            {/* Half Star */}
            {isFloat(ratings) && <i className="icon-n-06 icon-star-half" />}

            {/* Empty Star */}
            {Array.from({ length: 5 - ratings }, (_, i) => (
                <i key={i} className="icon-n-05 icon-star-empty" />
            ))}
        </div>
    );
};

ProductRatings.propTypes = {
    ratings: PropTypes.number.isRequired,
    className: PropTypes.string
};

export default ProductRatings;