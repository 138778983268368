import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../../assets/images/box-item/img3rank.jpg'
import imga1 from '../../../assets/images/avatar/author_rank.jpg'

const Winners = () => {
    const [data] = useState(
        [
            {
                img: img1,
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                token: 'IMATEID',
                date: 'Aug 11, 13:00',
                chain: 'Ethereum',
                amount: '200'
            },
            {
                img: img1,
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                token: 'IMATEID',
                date: 'Aug 11, 13:00',
                chain: 'Ethereum',
                amount: '200'
            },
            {
                img: img1,
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                token: 'IMATEID',
                date: 'Aug 11, 13:00',
                chain: 'Ethereum',
                amount: '200'
            },
            {
                img: img1,
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                token: 'IMATEID',
                date: 'Aug 11, 13:00',
                chain: 'Ethereum',
                amount: '200'
            },
            {
                img: img1,
                imgAuthor: imga1,
                nameAuthor: 'SalvadorDali',
                token: 'IMATEID',
                date: 'Aug 11, 13:00',
                chain: 'Ethereum',
                amount: '200'
            },

        ]
    )
    const [visible, setVisible] = useState(3);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);
    }
    return (
        <div>
            <section className="tf-section tf-rank">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-ranking table-winners">
                                <div className="flex th-title">
                                    <div className="column1">
                                        <h3>Token</h3>
                                    </div>
                                    <div className="column1">
                                        <h3>Winner</h3>
                                    </div>
                                    <div className="column">
                                        <h3>Date</h3>
                                    </div>
                                    <div className="column">
                                        <h3>Blockchain</h3>
                                    </div>
                                    <div className="column">
                                        <h3>Total Prize Money</h3>
                                    </div>
                                </div>
                                {
                                    data.slice(0, visible).map((item, index) => (
                                        <div key={index} className="fl-item2">
                                            <div className="item flex">

                                                <div className="infor-item flex column1">
                                                    <div className="media">
                                                        <img src={item.img} alt="Axies" />
                                                    </div>
                                                    <div className="content-collection pad-t-4">
                                                        <h5 className="title mb-15">{item.token}</h5>
                                                    </div>
                                                </div>
                                                <div className="infor-item flex column1">
                                                    <div className="author flex">
                                                        <div className="author-avatar">
                                                            <img src={item.imgAuthor} alt="Axies" />
                                                            <div className="badge"><i className="ripple"></i></div>
                                                        </div>
                                                        <div className="content">
                                                            <h6><Link to="/authors-01">{item.nameAuthor}</Link></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column td2">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="column td2">
                                                    <span>{item.chain}</span>
                                                </div>
                                                <div className="column td6">
                                                    <span>{item.amount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    visible < data.length &&
                                    <div className="col-md-12 wrap-inner load-more text-center">
                                        <Link to="#" id="load-more" className="sc-button loadmore fl-button pri-3" onClick={showMoreItems}><span>Load More</span></Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Winners;
